import React, {Component} from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';
import { ToastContainer } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from "moment";

export class ActivitySessions extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            activityType: null,
            loading: true,
            tableLoading: true,
            bookingModel: null,
            arrivalDateDayDifference: 0,
            changeBookingMode : false,
            savedActivityBooking: null,
            isChangeBookingSubmitting: false,
            showToast: false,
            toastMessage: null,
            message: null,
            showModal: false,
            modalMessage: null,
            activitySessionForChange: null
        };
        this.nextPreviousDay = this.nextPreviousDay.bind(this);
        this.showModalForChange = this.showModalForChange.bind(this);
        this.changeBooking = this.changeBooking.bind(this);
    }
    
    componentDidMount() {
        
        const storageBookedDetails = JSON.parse(localStorage.getItem("bookedDetails"));
        const activityTypeId = this.props.history.location.state.activityTypeId;
        let bookingModel = JSON.parse(localStorage.getItem("bookingModel"));
        const nonResidentValidFrom = localStorage.getItem("nonResidentValidFrom");
        const nonResidentValidTo = localStorage.getItem("nonResidentValidTo");

        if (storageBookedDetails && storageBookedDetails.activityTypeId !== activityTypeId) {
            localStorage.removeItem("bookedDetails");
        }

        if (!bookingModel && nonResidentValidFrom && nonResidentValidTo) {
            bookingModel = {
                arrivalDate : nonResidentValidFrom,
                departureDate: nonResidentValidTo
            };            
        }

        this.setState({
            bookingModel: bookingModel,            
            savedActivityBooking: this.props.history.location.state.savedActivityBooking, //this is coming from My Bookings
            changeBookingMode: this.props.history.location.state.changeBookingMode
        });

        this.populateData(activityTypeId, bookingModel.arrivalDate  ,0);
    }

    nextPreviousDay(value) {

        const newArrivalDateDayDifference = this.state.arrivalDateDayDifference + (1 * value);
        this.setState({
            arrivalDateDayDifference: newArrivalDateDayDifference
        });

        const activityTypeId = this.props.history.location.state.activityTypeId;
        this.populateData(activityTypeId, this.state.bookingModel.arrivalDate , newArrivalDateDayDifference);
    }

    saveChangedBooking = (activitySession) => {        
        this.setState({
            isChangeBookingSubmitting: true,
            message: null
        },
            () => this.changeBooking(activitySession)
        );               
    }

    showModalForChange = (activitySession) => {
        this.setState({
            activitySessionForChange: activitySession,
            showModal: true,
            modalMessage: `Are you sure you want to change into this session with time  ${activitySession.startTime}?`
        });
    }

    renderGrid(activityType, bookingModel) {
        return (            
            <div> 
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 py-2 text-center">
                            <h3>{activityType.activityTypeName} -&nbsp;
                                <Moment format="dddd DD/MM/yyyy">{activityType.currentDay}</Moment>
                            </h3>
                        </div>
                        {this.state.changeBookingMode &&
                            <div className="col-lg-9 text-center">
                                <h6>Current Booking -
                                    <Moment format="dddd DD/MM/yyyy h:mm A">{this.state.savedActivityBooking.startDate}</Moment>
                                </h6>
                            </div>
                        }
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-9 text-center">
                            <p>Your Sessions run daily from&nbsp;
                                <Moment format="DD/MM/yyyy">{bookingModel.arrivalDate}</Moment>                                
                                   &nbsp;and finish on&nbsp;
                                  <Moment format="DD/MM/yyyy">{bookingModel.departureDate}</Moment>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    {/*<div className="row">*/}
                    {/*    <div className="col">*/}
                    {/*        {this.state.arrivalDateDayDifference > 0 &&                                */}
                    {/*            <button onClick={() => this.nextPreviousDay(-1)} className="btn btn-outline-primary">Previous Day</button>*/}
                    {/*        }                            */}
                    {/*    </div>*/}
                    {/*    <div className="col">*/}
                    {/*        {activityType.currentDay !== bookingModel.departureDate &&*/}
                    {/*            <button onClick={() => this.nextPreviousDay(1)} className="btn btn-outline-primary float-right">Next Day</button>*/}
                    {/*        }*/}
                    {/*        */}
                    {/*    </div>*/}
                    {/*</div>                    */}
                    <div className="row py-2">
                        <br />
                        <div className="table-responsive">
                            <table className="table activities-table table-striped">
                                <thead className="thead-light">
                                    <tr>
                                        <th >Start</th>
                                        <th >Length (minutes)</th>
                                        <th >Available</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {this.state.tableLoading
                                    ?
                                    <tbody>
                                        <tr>
                                            <td colSpan="4">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <Spinner animation="border" />
                                                </div>
                                            </td>
                                        </tr>                                        
                                    </tbody>
                                    :
                                    <tbody>
                                        {activityType.activitySessions.map(record =>
                                            <tr key={record.activitySessionId}>
                                                <td className="text-lg-left">
                                                    <Moment format="h:mm A">{record.startDate}</Moment>
                                                </td>
                                                <td className="text-lg-left">
                                                    {record.sessionLength}
                                                </td>
                                                <td className="text-lg-left">
                                                    {record.available}
                                                </td>

                                                {record.available > 0 && !this.state.changeBookingMode &&

                                                    <td>
                                                        <button className="btn btn-outline-primary btn-block float-right"
                                                            onClick={() => {
                                                                this.props.history.push({
                                                                    pathname: '/booking-details',
                                                                    state: {
                                                                        activitySessionId: record.activitySessionId,
                                                                        chosenCurrentDay: activityType.currentDay,
                                                                        startDate: record.startDate,
                                                                        startTime: record.startTime,
                                                                        endTime: record.endTime,
                                                                        tickets: record.tickets,
                                                                        activityTypeId: activityType.activityTypeId,
                                                                        activityTypeName: activityType.activityTypeName,
                                                                        activityTypeDescription: activityType.activityTypeDescription
                                                                    }
                                                                })
                                                            }}>

                                                            Book </button>
                                                    </td>
                                                }
                                                {record.available > 0 && this.state.changeBookingMode
                                                    && record.startDate === this.state.savedActivityBooking.startDate &&

                                                    <td className="text-center text-lg-left">
                                                        <span >Currently Booked</span>
                                                    </td>
                                                }
                                                {record.available > 0 && this.state.changeBookingMode
                                                    && record.startDate !== this.state.savedActivityBooking.startDate &&
                                                    <td>
                                                        {/*<button className="btn btn-outline-primary btn-block float-right"                                                        */}
                                                        {/*onClick={() => {*/}
                                                        {/*        this.saveChangedBooking(record);*/}
                                                        {/*}}>Change </button>*/}

                                                        <button className="btn btn-outline-primary btn-block float-right"
                                                            onClick={() => this.showModalForChange(record)} >
                                                        Change </button>
                                                    
                                                                                                                                                                            
                                                    </td>
                                                    
                                                }
                                                {record.available === 0  &&

                                                    <td className="text-danger text-center py-sm-3">
                                                        FULLY BOOKED
                                                    </td>
                                                }
                                            </tr>
                                        )}
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>

                    {this.state.changeBookingMode
                        ?
                        <button onClick={() => this.props.history.push('/booked-activities')} className="btn btn-primary btn-block">Your Bookings</button>
                        :
                        <button onClick={() => this.props.history.push('/activities')} className="btn btn-primary btn-block">Change Activity</button>
                    }
                    
                    {this.state.isChangeBookingSubmitting &&
                        <div className="text-center mt-4">
                            <Spinner animation="border" />
                        </div>
                    }

                    {this.state.message &&
                        <div className="alert alert-success d-block text-center mt-1">
                            <span>{this.state.message}</span>
                        </div>
                    }

                    <ToastContainer position="top-right">
                        <Toast className="d-inline-block m-1" bg="warning"
                            onClose={() => this.setState({ showToast: false })}
                            delay={3000} autohide={true} show={this.state.showToast}>
                            <Toast.Body>
                                {this.state.toastMessage}
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>

                    <Modal show={this.state.showModal}>
                        <Modal.Header >
                            <Modal.Title>Please Confirm</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.modalMessage}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={() => this.setState({ showModal: false })}>
                                No
                            </Button>
                            <Button variant="success" onClick={this.changeBooking}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div >
            </div>
        )
    }

    render() {
        
        let contents = this.state.loading
            ? 
            <div className="h-400 p-5 d-flex align-items-center justify-content-center">
                <Spinner animation="border" />
            </div>
             
            : this.renderGrid(this.state.activityType, this.state.bookingModel);

        return (
            <div>                
                {contents}
            </div>
        );

    }

    async populateData(activityTypeId, startDateTime, arrivalDateDayDifference) {

        this.setState({ tableLoading: true });
        
        const response = await fetch(`activitysessions?activityTypeId=${activityTypeId}&startDateTime=${moment(startDateTime).utcOffset(0, true).format()
        }&arrivalDateDayDifference=${arrivalDateDayDifference}`);
        if (response.redirected) {            
            // expired session
            localStorage.clear();
            this.props.history.push({
                pathname: '/resident-login'
            })

            return;
        } 
        
        const data = await response.json();
        this.setState({ activityType: data, loading: false, tableLoading: false });
        localStorage.setItem("activitySessionsForChosenDay", JSON.stringify(data.activitySessions));
    }
    
    async changeBooking() {

        this.setState({
            isChangeBookingSubmitting: true,
            message: null,
            showModal: false
        });

        const activitySession = this.state.activitySessionForChange;

        var payload = this.state.savedActivityBooking;
        payload.surname = this.state.bookingModel.surname;
        payload.postcode = this.state.bookingModel.postcode;
        payload.newStartDate = activitySession.startDate;
        payload.newStartTime = activitySession.startTime;
        payload.newEndTime = activitySession.endTime;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload) 
                
        };
        const response = await fetch('privatebooking/change-booking', options);
        const data = await response.json();
        if (data.actvityBookingId === 0) {
            
            this.setState({
                isChangeBookingSubmitting: false,
                toastMessage: data.message,
                showToast: true
            });
        } else if (data.actvityBookingId > 0) {

            this.setState({
                isChangeBookingSubmitting: false,
                message: `Booking with reference # ${data.actvityBookingId} changed successfully!`
            });

            setTimeout(() => {
                this.props.history.push('/booked-activities')
            },
                1500
            );
            
        }
    }
    
}
