import React, {Component} from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';

/*
 * Activity Ticket and Qty page
 */
export class BookingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            bookingModel: null,
            activitySession: null,
            errorMessage: null,
            orderedTickets: null,
            isValidatingQty : false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateSessionQty = this.validateSessionQty.bind(this);
    }
    
    
    componentDidMount() {
        
        this.setState({
            activitySession: this.props.history.location.state,
            loading: false
        });        
        
    }

    componentDidUpdate(prevProps, prevState) {
        // from saved state at localStorage
        const bookedDetails = JSON.parse(localStorage.getItem("bookedDetails"));
        if (bookedDetails && bookedDetails.orderedTickets) {
            var ticketInputs = Array.from(document.getElementsByClassName("form-control"));            
            bookedDetails.orderedTickets.forEach(ticket => {                
                const isIdMatched = (element) => element.name === ticket.activityId;
                const activityInput = ticketInputs.find(isIdMatched);                
                activityInput.value = ticket.qty;
            });
        }
    }

    previous() {

        this.props.history.push({
            pathname: '/activity-sessions',
            state: { activityTypeId: this.state.activitySession.activityTypeId }
        })
            
    }

    async handleSubmit(event) {
        event.preventDefault();
        
        var orders = [];
        for (var i = 0; i < event.target.length; i++) {
            
            if (event.target[i].value && parseInt(event.target[i].value) > 0 ) {
                const activityId = parseInt(event.target[i].name);
                const isIdMatched = (element) => element.activityId === activityId;
                const activity = this.state.activitySession.tickets.find(isIdMatched);
                
                var order = {
                    activityId: event.target[i].name,
                    qty: parseInt(event.target[i].value),
                    name: activity.activityName,
                    price: activity.price,
                    gross: parseInt(event.target[i].value) * activity.price
                };
                orders.push(order);                    
            }                        
        }

        if (orders.length === 0) {
            this.setState({ errorMessage: "You need to select at least 1 item with a non-zero quantity." });
            return false;
        }

        //const activitySessionsForChosenDay = JSON.parse(localStorage.getItem("activitySessionsForChosenDay"));
        //const orderedSessionAndQtyMatch = activitySessionsForChosenDay
        //    .find(x => x.activitySessionId === this.state.activitySession.activitySessionId
        //        && x.startTime === this.state.activitySession.startTime);
        
        this.setState({ orderedTickets: orders });

        const totalOrderedQty = orders.map(item => item.qty).reduce((prev, curr) => prev + curr, 0);

        const isValidated = await this.validateSessionQty(this.state.activitySession, totalOrderedQty);
        
        if (!isValidated) {
            return false;
        }
                    
        //if (orderedSessionAndQtyMatch && orderedSessionAndQtyMatch.available < totalOrderedQty) {
        //    this.setState({ errorMessage: `We only have ${orderedSessionAndQtyMatch.available} available slots left. Please change your chosen numbers or select another session.` });
        //    return false;
        //}
        
        const bookedDetails = {
            activityTypeId: this.state.activitySession.activityTypeId,
            activityTypeName: this.state.activitySession.activityTypeName,
            startDate: this.state.activitySession.startDate,
            startTime: this.state.activitySession.startTime,
            endTime: this.state.activitySession.endTime,
            activitySessionId: this.state.activitySession.activitySessionId,
            chosenCurrentDay: this.state.activitySession.chosenCurrentDay,
            tickets: this.state.activitySession.tickets,
            orderedTickets: orders
        };

        localStorage.setItem("bookedDetails", JSON.stringify(bookedDetails));

        this.props.history.push({
            pathname: '/confirm-details',
            state: bookedDetails            
        })
    }

    renderGrid() {
        
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 py2">
                     <div className="py-2 text-center">
                            <h3>
                                {this.state.activitySession.activityTypeName}
                                &nbsp;-&nbsp; 
                                <Moment format="DD/MM/yyyy h:mm A">{this.state.activitySession.startDate}</Moment>
                            </h3>                            
                        </div>
                        
                        <div className="text-sm-left py-2" dangerouslySetInnerHTML={{ __html: this.state.activitySession.activityTypeDescription }} />

                        <div className="row">
                            <div className="col-md-12">
                                <p className="">Please select your number of places.</p>
                            </div>
                            {this.state.errorMessage &&

                                <div className="alert alert-danger d-block text-center" role="alert">
                                    {this.state.errorMessage}
                                </div>
                            }
                        </div>                        

                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">

                                {this.state.activitySession.tickets.map(record =>

                                    <div className="row" key={record.activityId}>
                                        <div className="col mb-6 my-3">
                                            <label>
                                                {record.activityName}
                                            </label>
                                        </div>
                                        <div className="col mb-6 my-3">                                            
                                            <input className="form-control"
                                                type="number"
                                                min={record.minPeople}
                                                max={record.maxPeople}
                                                name={record.activityId}
                                                htmlFor={record.activityName}
                                                defaultValue="0"
                                            />
                                        </div>
                                    </div>
                                )}
                                                                            
                            </div>

                            <div className="form-group">
                                <button className="btn btn-primary btn-block" type="submit"
                                    >

                                    Complete Booking
                                </button>

                                {this.state.isValidatingQty &&
                                    <div className="text-center mt-2">
                                        <Spinner animation="border" />
                                    </div>
                                }

                                <span className="btn btn-block btn-outline-primary" onClick={() => this.previous()} >
                                    Change Session
                                </span>
                            </div>

                        </form>

                    </div>
                </div >
            </div >
        )
    }

    render() {

        let contents = this.state.loading
            ?
            <Spinner animation="border" />

            : this.renderGrid();
            
        return (
            <div>                
                {contents}
            </div>
        );

    }
    
    async validateSessionQty(stateActivitySession, qty) {

        this.setState({ isValidatingQty: true , errorMessage : `Validating...`});

        const activityTypeId = stateActivitySession.activityTypeId;
        const activitySessionId = stateActivitySession.activitySessionId;
        const startDateTime = stateActivitySession.startDate;

        const response = await fetch(`activitysessions/check-available?activityTypeId=${activityTypeId}&activitySessionId=${activitySessionId}&startDateTime=${startDateTime}`);        
        const data = await response.json();
        if (data) {
            
            if (qty > data.available) {
                this.setState({ errorMessage: `We only have ${data.available} available slots left. Please change your chosen numbers or select another session.` });
                this.setState({ isValidatingQty: false});
                return false;
            }

            this.setState({ isValidatingQty: false, errorMessage: ``});
            return true;
        } else {
            this.setState({ isValidatingQty: false, errorMessage: ``});
            return false;
        }
    }
}
