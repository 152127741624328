import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';

export class Activities extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            loading: true
        };
    }
    
    componentDidMount() {

        const sessionId = Date.now().toString(36) + Math.random().toString(36).substring(2);
        localStorage.setItem("sessionId", sessionId);
        this.populateData();                
    }
    
    renderGrid(records,props) {
        return (
         <div>
                
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 py-2 text-center">
                        <h3>Booking Activity</h3>
                        <p>Choose your activity from the list below and click select to book your session.</p>
                    </div>
                </div>
            </div>                
                {records.length > 0
                    ? <div className="container-fluid">
                        <div className="row py-2">
                            <div className="table-responsive">
                                <table className="table activities-table">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Activity</th>
                                            {/*<th>Valid from</th>*/}
                                            {/*<th>Valid to</th>*/}
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {records.map(record =>
                                            <tr key={record.activityTypeId}>
                                                <td>{record.activityTypeName}</td>
                                                {/*<td>*/}
                                                {/*    <Moment local format="DD/MM/yyyy">{record.validFrom.toString()}</Moment>*/}
                                                {/*</td>*/}
                                                {/*<td>                                                    */}
                                                {/*    <Moment local format="DD/MM/yyyy">{record.validTo.toString()}</Moment>*/}
                                                {/*</td>*/}
                                                <td>
                                                    <button
                                                        className="btn  btn-outline-primary"
                                                        onClick={() => {
                                                            props.history.push({
                                                                pathname: '/activity-sessions',
                                                                state: {
                                                                    activityTypeId: record.activityTypeId
                                                                }
                                                            })}}>
                                                        Select
                                                    </button>
                                                    
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    : <div className="alert alert-danger d-block text-center">
                        <span>Sorry, no available activities.</span>
                    </div>
                }
        </div>
        )
    }

    render() {
        let contents = this.state.loading
            ?

            <div className="h-400 p-5 d-flex align-items-center justify-content-center">
                <Spinner animation="border" />
            </div>

            : this.renderGrid(this.state.records, this.props);

        return (
            <div>                
                {contents}                
            </div>
        );  
        
    }

    async populateData() {
        const bookingModel = JSON.parse(localStorage.getItem("bookingModel"));

        let query = '';
        if (bookingModel) 
            query = 'validFrom=' + bookingModel.arrivalDate + '&validTo=' + bookingModel.departureDate + '&loginStatus=' + bookingModel.loggedIn;        

        const response = await fetch('activities?' + query);
        
        const data = await response.json();
        if (data) {
            this.setState({ records: data, loading: false });

            if (!bookingModel && data.length > 0) {
                localStorage.setItem("nonResidentValidFrom", data[0].validFrom);
                localStorage.setItem("nonResidentValidTo", data[0].validTo);
            }            
        }
        
    }

    
    
}
