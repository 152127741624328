import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export class BookedActivities extends Component {
    
    static displayName = BookedActivities.name;

    constructor(props) {
        super(props);
        this.state = {
            residentBooking: null,
            loading: true,
            isCancelBookingSubmitting: false,
            message: null,
            showModal: false,
            modalMessage: null,
            activityForCancel: null
        };
        this.loadChangeBookingActivity = this.loadChangeBookingActivity.bind(this);
        //this.cancelBookedActivity = this.cancelBookedActivity.bind(this);
        this.showModalForCancel = this.showModalForCancel.bind(this);
        this.cancelBooking = this.cancelBooking.bind(this);
    }
    
    componentDidMount() {
        var bookingModel = localStorage.getItem("bookingModel");
        if (!bookingModel) {
            this.props.history.push('/resident-login');
        } else {
            this.populateData();
        }
    }

    loadChangeBookingActivity = (record) => {
                
        this.props.history.push({
            pathname: '/activity-sessions',
            state: {
                activityTypeId: record.activityTypeId,
                savedActivityBooking: record,
                changeBookingMode: true
            }
        })
    }

    //cancelBookedActivity = (record) => {

    //    this.cancelBooking(record);

    //}

    showModalForCancel = (activityBooking) => {
        this.setState({
            activityForCancel: activityBooking,
            showModal: true,
            modalMessage: `Are you sure you want to cancel this booked activity # ${activityBooking.activityBookingId}?`
        });
    }

    renderGrid(residentBooking, props) {
        return (
                         
            <div>
                <div className="py-2 text-center">
                    <h3>Your Bookings</h3>
                    <p className="lead">Below are your currently booked activities.</p>
                </div>

                {this.state.message &&
                    <div className="alert alert-success d-block text-center">
                        <span>{this.state.message}</span>
                    </div>
                }

                <div className="container-fluid">
                    <div className="row py-2">
                        <br />
                        <div className="table-responsive">
                            <table className="table activities-table table-striped">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Activity</th>
                                        <th>Start Time</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {this.state.isCancelBookingSubmitting
                                    ?
                                    <tbody>
                                        <tr>
                                            <td colSpan="4">
                                                <Spinner animation="border" />
                                            </td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        {residentBooking?.activityBookings?.map(record =>
                                            <tr key={record.activityBookingId}>
                                                <td className="text-lg-left">
                                                    {record.activityName} <br />
                                                    <span className="text-muted">Reference: {record.activityBookingId}</span>
                                                </td>
                                                <td className="text-lg-left">
                                                    <Moment format="DD/MM/yyyy h:mm A">{record.startDate}</Moment>
                                                </td>

                                                <td>
                                                    <button className="btn btn-outline-primary btn-block"
                                                        onClick={() => this.loadChangeBookingActivity(record)} >
                                                        Change
                                                    </button>
                                                    { // <!-- add condition here for allowCancel --> 
                                                    }
                                                    {/*<button className="btn btn-outline-primary btn-block"*/}
                                                    {/*    onClick={() => this.cancelBookedActivity(record)} >Cancel</button>*/}

                                                    <button className="btn btn-outline-primary btn-block"
                                                        onClick={() => this.showModalForCancel(record)} >Cancel</button>

                                                </td>

                                            </tr>
                                        )}
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>

                </div>

                <Modal show={this.state.showModal}>
                    <Modal.Header >
                        <Modal.Title>Please Confirm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.modalMessage}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => this.setState({ showModal : false}) }>
                            No
                        </Button>
                        <Button variant="success" onClick={this.cancelBooking}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>                    
        );
    }

    render() {
        let contents = this.state.loading
            ?
            
            <div className="h-400 p-5 d-flex align-items-center justify-content-center">
                <Spinner animation="border" />
            </div>
            
            : this.renderGrid(this.state.residentBooking, this.props);

        return (
            <div>
                {contents}
            </div>
        );

    }

    
    async populateData() {
        
        var residentLogin = localStorage.getItem("residentLogin");

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: residentLogin
        };
        
        const response = await fetch('mybookings/get-bookings', options);

        if (response.redirected) {
            // expired session
            localStorage.clear();
            localStorage.setItem('refreshResidentLogin', 'true');

            this.props.history.push({
                pathname: '/resident-login'                
            });            

            return;
        }

        const data = await response.json();
                
        this.setState({ residentBooking: data, loading: false });
    }

    

    //async cancelBooking(activityBooking) {
    async cancelBooking() {

        this.setState({
            isCancelBookingSubmitting: true,
            showModal: false
        });
                
        var payload = this.state.activityForCancel;
        payload.surname = this.state.residentBooking.surname;
        payload.postcode = this.state.residentBooking.postcode;
        
        
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)

        };
        const response = await fetch('privatebooking/cancel-booking', options);        
        const data = await response.json();
        if (data.actvityBookingId > 0 && data.message.includes("Booking Cancelled") ) {

            this.setState({
                isCancelBookingSubmitting: false,
                message: `Booking with reference # ${data.actvityBookingId} cancelled successfully!`
            });

            this.populateData();
        } 
    }
}
