import React, {Component} from 'react';
import Spinner from 'react-bootstrap/Spinner';


export class ResidentLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            surname: '',
            postcode: '',
            voucher: '',
            error: '',
            loginStatus: false,
            submitting: false,
            errorMessage: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onTriggerLogin = () => {        
        this.props.rootLoginCallback();
    };

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        this.setState({ submitting: true });
        this.fetchUser(this.state);
        event.preventDefault();
    }

    componentDidMount() {

        if (localStorage.getItem('refreshResidentLogin')) {
            localStorage.clear();
            window.location.reload(true);
        }
            
    }

    render() {
        
        return (
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-9">
                <div className="py-5 text-center">
                    <h2>Book your Activity</h2>
                    <p className="lead">Please enter your customer booking details.</p>
                </div>

                {this.state.errorMessage &&
                    <div className="alert alert-danger d-block text-center">
                            <span>{this.state.errorMessage}</span>
                    </div>
                }                
                <form onSubmit={this.handleSubmit}>
                    
                    <div className="py-2">
                        <label htmlFor="surname">Surname</label>
                        <input type="text" className="form-control" value={this.state.value} name="surname" id="surname" placeholder="Surname"
                               onChange={this.handleChange}/>
                    </div>
                    {/*<div className="py-2">*/}
                    {/*    <label htmlFor="postcode">Postcode</label>*/}
                    {/*    <input type="text" className="form-control" value={this.state.value} name="postcode" id="postcode" placeholder="Postcode"*/}
                    {/*           onChange={this.handleChange}/>*/}
                    {/*</div>*/}
                    <div className="py-2">
                        <label htmlFor="voucher">Passcode</label>
                        <input type="text" className="form-control" value={this.state.value} name="voucher" id="voucher" placeholder="Passcode"
                               onChange={this.handleChange}/>
                    </div>
                    <div className="text-center mt-4">
                        {this.state.submitting
                            ?
                            
                             <Spinner animation="border" />
                            
                            :
                            <button type="submit" className="btn d-block d-lg-inline mx-auto btn-primary">Proceed</button>
                        }
                        
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
        );  
        
    }

    async fetchUser() {

        if (isNaN(this.state.voucher)) {
            this.setState({
                errorMessage: "Voucher should be numeric!",
                submitting: false
            });
            return false;
        }

        if (!this.state.voucher || !this.state.surname) {
            this.setState({
                errorMessage: "All fields are required!",
                submitting: false
            });
            return false;
        }

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state)
        };

        const response = await fetch('residentlogin', options);
        const data = await response.json();
        
        if (data.loggedIn) {

            this.onTriggerLogin(); // rootcallback
            localStorage.setItem("residentLogin", JSON.stringify(this.state));
            localStorage.setItem("bookingModel", JSON.stringify(data));

            this.props.history.push('/activities');
            
        } else if (data.activityBookings.length === 0) {

            this.setState({
                errorMessage: "Sorry, we can't find a booking for this account. Please check your details and try again.",
                submitting: false
            });
        }
        
    }
    
}
