import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import { ToastContainer } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { ChargeDetails } from './ChargeDetails';


export class ConfirmDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            bookingModel: null,
            bookedDetails: null,
            message: null,
            totalSum: 0,
            balance: 0,            
            showModal: false,
            termsChecked: false,
            showToast: false,
            toastMessage: 'Please accept the Terms and Conditions by ticking on the checkbox.',
            showChargesOnly: false,            
            paymentClientSecret: null,

            showVoucherField: false,
            voucherCode: null,            
            isCheckingVoucher: false,
            voucherAmount: 0,
            voucherMessage: null,
            validatedVoucher: null,

            isValidatingBooking: false,
            isBookingValidated: false,
            bookingValidationMessage: null,
            validatedCharges : null,  // same object as bookedDetails.orderedTickets but this is validated            
        };
        
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.prepareForPayment = this.prepareForPayment.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.moveToPayment = this.moveToPayment.bind(this);
    }
    
    componentDidMount() {

        localStorage.removeItem("appliedVoucher");

        let bookingModel = JSON.parse(localStorage.getItem("bookingModel"));
        let nonResidentModel = JSON.parse(localStorage.getItem("nonResidentModel"));
        const orderedTickets = this.props.history.location.state.orderedTickets;
        const totalSum = orderedTickets.map(item => item.gross).reduce((prev, curr) => prev + curr, 0);

        if (!bookingModel && !nonResidentModel) {
            bookingModel = {
                bookingId: 0,
                accountId: 0,
                address1: '',
                address2: '',
                address3: '',
                country: '',
                county: '',
                email: '',
                firstName: '',
                phone: '',
                postcode: '',
                surname: '',
                town: ''
            }
        } else if (!bookingModel && nonResidentModel) {
            bookingModel = nonResidentModel;
        }

        this.setState({
            bookedDetails: this.props.history.location.state,            
            bookingModel: bookingModel,
            totalSum: totalSum,
            balance: totalSum - 0,
            formSubmitting: false,
            loading: false
        });
        
    }

    previous() {
        
        this.props.history.push({
            pathname: '/activity-sessions',
            state: { activityTypeId: this.state.bookedDetails.activityTypeId }
        })

    }

    handleClose = () => this.setState({ showModal : false});
    handleShow = () => this.setState({ showModal: true });

    handleChange(event) {

        if (event.target.name === 'voucherCode') {
            this.setState({ voucherCode: event.target.value });  // root state
        } else {
            this.setState(prevState => ({  // nested state use spread syntax
                bookingModel: {
                    ...prevState.bookingModel,
                    [event.target.name]: event.target.value
                }
            }));
        }
        
    }
    
    renderGrid(bookingModel) {
            
        return (
            <div className="container">
                                                   
                <div>
                    <div className="row">

                        <div className="col-md-4 py-2 order-md-2 mb-4">
                            <ChargeDetails
                                bookedDetails={this.state.bookedDetails}
                                totalSum={this.state.totalSum}
                                voucherAmount={this.state.voucherAmount}
                                balance={this.state.balance}
                            />
                        </div>

                        <div className="col-md-8 py-2 order-md-1">
                            <h2 className="mb-3">Customer Details</h2>

                            <div className="row">
                                <div className="col-md-6 mb-10">
                                    <label htmlFor="FirstName">First name</label>
                                    <input id="FirstName" className="form-control" type="text" name="firstName"
                                        defaultValue={bookingModel.firstName}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="LastName">Last name</label>
                                    <input id="LastName" className="form-control" type="text" name="surname"
                                        defaultValue={bookingModel.surname}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Address1">Address 1</label>
                                <input id="Address1" className="form-control" name="address1"
                                    defaultValue={bookingModel.address1}
                                    onChange={this.handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Address2">Address 2</label>
                                <input id="Address2" className="form-control" name="address2"
                                    defaultValue={bookingModel.address2}
                                    onChange={this.handleChange}  />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Town">Town</label>
                                <input id="Town" className="form-control" name="town"
                                    defaultValue={bookingModel.town}
                                    onChange={this.handleChange}  />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="Postcode">Postcode</label>
                                <input id="Postcode" className="form-control" name="postcode"
                                    defaultValue={bookingModel.postcode}
                                   onChange={this.handleChange}    />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Email">Email</label>
                                <input id="Email" className="form-control" name="email"
                                    defaultValue={bookingModel.email}
                                    onChange={this.handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Phone">Phone</label>
                                <input type="text" id="Phone" className="form-control" name="phone"
                                    defaultValue={bookingModel.phone}
                                   onChange={this.handleChange}  />
                            </div>
                            <div className="mb-3">
                                <ToastContainer position="top-right">
                                    <Toast className="d-inline-block m-1" bg="warning"
                                        onClose={() => this.setState({ showToast: false })}
                                        delay={3000} autohide={true} show={this.state.showToast}>
                                        <Toast.Body>
                                            {this.state.toastMessage}
                                        </Toast.Body>
                                    </Toast>
                                </ToastContainer>

                                <input id="Terms" className="form-check-input mx-1"
                                    type="checkbox" name="Terms" defaultChecked={this.state.termsChecked}
                                    onChange={() => this.setState({ termsChecked: !this.state.termsChecked })}
                                />

                                <label className="form-check-label mx-4" htmlFor="Terms">Please tick to accept terms and conditions for this booking.</label>
                                <button onClick={this.handleShow} type="button" className="btn btn-outline-primary" >
                                    View terms and conditions
                                </button>
                            </div>
                            <div className="row">
                                {!this.state.showVoucherField
                                    ?
                                    <div className="mb-2">
                                        <span className="btn btn-outline-primary btn-block"
                                            onClick={() => this.setState({ showVoucherField: true })} >
                                            Apply Voucher
                                        </span>
                                    </div>
                                    :
                                    <div className="row mb-2">
                                        <div className="col-md-6">
                                            <input id="voucherCode" className="form-control" type="text" name="voucherCode"
                                                value={this.state.value}
                                                onChange={this.handleChange}
                                                placeholder="Voucher Code"
                                            />
                                        </div>
                                        <div className="col-md-6">                                                

                                            {!this.state.isCheckingVoucher
                                                ?
                                                <span className="btn btn-outline-primary btn-block"
                                                    onClick={this.checkVoucher} >
                                                    Apply
                                                </span>
                                                :
                                                <>
                                                    <Spinner animation="border" />
                                                </>
                                            }
                                        </div>                                            
                                    </div>
                                }

                                {this.state.voucherMessage &&
                                    <div className="alert alert-danger text-center mt-1">
                                        <span>{this.state.voucherMessage}</span>
                                    </div>
                                }

                                <div className="col col-md-12 text-center">
                                    <button className="btn btn-primary btn-block"
                                        onClick={this.prepareForPayment}>
                                        Confirm
                                    </button>

                                    {this.state.isValidatingBooking &&                                            
                                        <div className="text-center mt-2">
                                            <Spinner animation="border" />
                                        </div>
                                    }

                                    {this.state.bookingValidationMessage &&                                                                                    
                                        <div className="alert alert-danger text-center">
                                            <span>{this.state.bookingValidationMessage}</span>
                                        </div>                                        
                                    }

                                    <span className="btn btn-block btn-outline-primary"
                                        onClick={() => this.previous()}
                                    >
                                        Change Session
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Modal show={this.state.showModal}>
                    <Modal.Header >
                        <Modal.Title>Terms and Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        For the safety and enjoyment of all of our guests and leaseholders we ask that all members of your group the the time to familiarise yourself with some simple “house rules”
                        <ul>
                            <li>Children under the age of 8 are to accompanied by a responsible swimming adult of at least 18 years of age who must remain in the pool with the minor at all times regardless of swimming ability.</li>
                            <li>One adult may accompany up to 2 children under the age of 8</li>
                            <li>Children under 5 must be accompanied on a 1:1 basis by a swimming adult who must remain in the pool with the minor at all times regardless of swimming ability.</li>
                            <li>Children between 8 and 15 must prove their swimming competence to the pool duty attendant prior to swimming un-accompanied in the pool and by accompanied by a responsible swimming adult who must remain in the pool complex but not necessarily in the pool.</li>
                            <li>Non swimmers are to be accompanied by a responsible swimmer (over 18) throughout their visit in the pool complex and the responsible swimmer must remain in the pool at all times with the non-swimmer.</li>
                            <li>For reasons of safety and for the enjoyment of all our pool users, ball games, masks, snorkels and large inflatables are not permitted in the pool.</li>
                            <li>Do not run around the poolside as the floors may be slippery.</li>
                            <li>Children using armbands and other swimming aids must be constantly supervised.</li>
                            <li>Please do not run, fight, bomb, duck or behave in such a way to cause a danger to yourselves or others using the pool.</li>
                            <li>A strict NO DIVING policy operates in the pool complex.</li>
                            <li>No glassware poolside.</li>
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            OK
                        </Button>                        
                    </Modal.Footer>
                </Modal>                
                
            </div>

         )
    }

    render() {
        
        let contents = this.state.loading
            ? <Spinner animation="border" />
            : this.renderGrid(this.state.bookingModel);

        return (
            <div>                
                {contents}
            </div>
        );

    }

    async prepareForPayment() {

        if (!this.state.termsChecked) {
            this.setState({
                toastMessage: 'Please accept the Terms and Conditions by ticking on the checkbox.',
                showToast: true
            });
            return false;
        }

        if (!this.state.bookingModel.surname || !this.state.bookingModel.postcode
            || !this.state.bookingModel.address1 || !this.state.bookingModel.email) {
            this.setState({
                toastMessage: 'Email, Surname, Address1, and Postcode are required!',
                showToast: true
            });
            return false;
        }

        if (this.state.isBookingValidated) { // validated already in voucher check
            const totalSumValidated = this.state.validatedCharges.map(item => item.gross).reduce((prev, curr) => prev + curr, 0);
            if (totalSumValidated > 0) {
                this.moveToPayment();
                return true;
            } else { // no need payment

                if (this.state.loginStatus === true) {
                    localStorage.setItem("bookingModel", JSON.stringify(this.state.bookingModel));
                } else {
                    localStorage.setItem("nonResidentModel", JSON.stringify(this.state.bookingModel));
                }
                
                this.props.history.push({
                    pathname: '/payment-success'                                    
                });
                return true;
            }
        }
        
        this.setState({ isValidatingBooking: true });

        // validate booking here as everything is ready for payment
        const vouchers = [];
        if (this.state.validatedVoucher) {
            vouchers.push(this.state.validatedVoucher);
        }

        let payload = {
            accountId:      this.state.bookingModel.accountId,
            bookingId:      this.state.bookingModel.bookingId,
            surname:        this.state.bookingModel.surname,
            postcode:       this.state.bookingModel.postcode,
            activityTypeId: this.state.bookedDetails.activityTypeId,
            activitySessionId: this.state.bookedDetails.activitySessionId,
            startDate:      this.state.bookedDetails.startDate,
            endTime:        this.state.bookedDetails.endTime,
            charges:        this.state.bookedDetails.orderedTickets,
            vouchers:       vouchers            
        };
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        };

        const response = await fetch('booking/validate-booking', options);

        if (response.redirected) {
            // expired session
            localStorage.clear();
            this.props.history.push({
                pathname: '/resident-login'
            })

            return;
        }
        
        const data = await response.json();
        
        if (data.message === "Booking Validated") {

            //we get the intent in the payment page                        
            this.setState({
                isBookingValidated: true,
                validatedCharges: data.activityBookingRequest.charges,
                totalSum: data.activityBookingRequest.total,
                balance: data.activityBookingRequest.balance
            });
        
           
          
            if (this.state.loginStatus === true) {
                localStorage.setItem("bookingModel", JSON.stringify(this.state.bookingModel));
            } else {
                localStorage.setItem("nonResidentModel", JSON.stringify(this.state.bookingModel));
            }
            
            if (data.activityBookingRequest.balance > 0) {
                this.moveToPayment();
            } else {
                this.props.history.push({
                    pathname: '/payment-success'                    
                });
            }
            
        } else {

            this.setState({
                isValidatingBooking: false,
                bookingValidationMessage: "An error occurred in validating this booking. We can't proceed with payment."
            });            
        }                
    }


    checkVoucher = async () => {

        this.setState({
            isCheckingVoucher: true
        })

        let activityBookingRequest = {
            accountId: this.state.bookingModel.accountId,
            bookingId: this.state.bookingModel.bookingId,
            surname: this.state.bookingModel.surname,
            postcode: this.state.bookingModel.postcode,
            activityTypeId: this.state.bookedDetails.activityTypeId,
            activitySessionId: this.state.bookedDetails.activitySessionId,
            startDate: this.state.bookedDetails.startDate,
            endTime: this.state.bookedDetails.endTime,
            charges: this.state.bookedDetails.orderedTickets            
        };
        
        var payload = {
            accountId: this.state.bookingModel.accountId,
            activityTypeId: this.state.bookedDetails.activityTypeId,
            voucher: this.state.voucherCode,
            actvityDate: this.state.bookedDetails.startDate,
            activityBookingRequest: activityBookingRequest
        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        };
        
        const response = await fetch('payment/check-voucher', options);

        if (response.redirected) {
            // expired session
            localStorage.clear();
            this.props.history.push({
                pathname: '/resident-login'
            })

            return;
        }        

        const dataValidatedBooking = await response.json();

        const validatedActivity = dataValidatedBooking.activityBookingRequest;
        const vouchers = validatedActivity.vouchers;

        let voucherApplied = validatedActivity.voucherApplied;
        if (voucherApplied > 0 || (vouchers.length > 0 && vouchers[0].activityVoucherId > 0)) {
            
            if (voucherApplied === 0) {  // this is not needed if voucherApplied will have non zero value from api
                const appliedVoucherType = validatedActivity.charges.filter(record => record.chargeType === "Voucher");                
                voucherApplied = appliedVoucherType.length > 0 ? -1 * appliedVoucherType[0].gross : 0;
            }

            this.setState({
                isCheckingVoucher: false,
                voucherAmount: voucherApplied,                
                showVoucherField: false,
                voucherMessage: "Voucher successfully applied.",
                balance: validatedActivity.balance,
                validatedVoucher: vouchers[0]
            });
            
            localStorage.setItem("appliedVoucher", JSON.stringify(vouchers[0]));
            
            if (dataValidatedBooking.message === "Booking Validated"
                && validatedActivity.charges.length > 0) {

                this.setState({
                    isBookingValidated: true,
                    validatedCharges: validatedActivity.charges
                });
            }            

        } else {

            this.setState({                
                isCheckingVoucher: false,
                voucherAmount: 0,
                showVoucherField: true,
                voucherMessage: "Sorry, your voucher is invalid.",
                balance: this.state.totalSum,
                isBookingValidated: false,
                validatedCharges: null,
                validatedVoucher: null
            })
        }
    }
    
    moveToPayment() {
        this.props.history.push({
            pathname: '/payment',
            state: {
                bookedDetails:      this.state.bookedDetails,
                totalSum:           this.state.totalSum, // totalSum here is with no discounts/vouchers 
                voucherAmount:      this.state.voucherAmount,
                balance:            this.state.balance,
                validatedCharges:   this.state.validatedCharges
            }
        });
    }
}
