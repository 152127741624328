import React, {Component} from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

export class ChargeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }
        
    componentDidMount() {
                
    }

    
    renderGrid() {
        
        return (
        <div >
            <h3 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your Booking</span>
            </h3>
            <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 className="my-0">Activity</h6>
                        <small className="text-muted">{this.props.bookedDetails.activityTypeName}</small>
                    </div>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 className="my-0">Start date:</h6>
                        <small className="text-muted">
                                <Moment format="DD/MM/yyyy h:mm A">{this.props.bookedDetails.startDate}</Moment>
                        </small>
                    </div>
                </li>
            </ul>

            <h3 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your Charges</span>
            </h3>

            <ul className="list-group mb-3">
                            
               {this.props.bookedDetails.orderedTickets.map(record =>
                                                            
                    <li className="list-group-item d-flex justify-content-between lh-condensed"
                        key={record.activityId }    >
                        <div>
                            <h6 className="my-0">{record.qty} x {record.name}</h6>
                        </div>
                        <span className="text-muted">&pound;{record.gross}</span>
                    </li>                            
                )}
                            
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 className="my-0">Total</h6>
                    </div>
                    <span className="text-muted">
                         &pound;{this.props.totalSum }
                    </span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 className="my-0">Vouchers</h6>
                    </div>
                    <span className="text-muted">
                         &pound;{this.props.voucherAmount}
                    </span>
                </li>
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h6 className="my-0">Balance</h6>
                    </div>
                    <span className="text-muted">
                        &pound;{this.props.balance}
                    </span>
                </li>
            </ul>

            
        </div>
         )
    }

    render() {
        
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderGrid();

        return (
            <div>                
                {contents}
            </div>
        );

    }

}
