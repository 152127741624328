import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        
        this.state = {
            
        };
    }

    componentDidMount() {
        
    }

    handleMenuLogoutCallback = () => {                
        this.props.rootLogoutCallback();
    }

    

    render() {        

    return (
        <div>
            <div className="page">

                <div className="sidebar">
                    <NavMenu layoutLogoutCallback={this.handleMenuLogoutCallback}
                        loggedIn={this.props.loggedIn}
                        />
                </div>
                
                <Container>                    
                    {this.props.children}
                </Container>

            </div>
            <footer className="footer mt-auto py-3 bg-light">
                <div className="container">
                    <span className="text-muted">Kenegie Manor Holiday Park</span>
                    <br />
                    <a target="_blank" rel="noreferrer" href="https://www.kenegie-manor.co.uk/terms-and-conditions/">Terms and Conditions</a>
                </div>
            </footer>
        </div>
    );
  }
}
