import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHomeLg, faPlus, faBookOpen, faBook, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: true       
    };
  }

    onTriggerLogout = () => {
        this.setState({ loggedIn: false });
        localStorage.clear()
        this.props.layoutLogoutCallback();        
    };

    toggleNavbar () {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

  
  render () {
      return (
      
     <div>
        <div className="navbar-top-row pl-4 navbar navbar-dark navbar-fixed-top">
            <span className="navbar-brand" >Activity Booking</span>
            <button className="navbar-toggler" onClick={this.toggleNavbar}>
                <span className="navbar-toggler-icon"></span>
            </button>
        </div>

        <div className={this.state.collapsed? "collapse" : null} onClick={this.toggleNavbar}>
                  
                      

                {!this.props.loggedIn
                    ?
                    <ul className="nav flex-column">
                        <li className="nav-item px-3">

                            <NavLink tag={Link} to="/">
                                <FontAwesomeIcon icon={faHomeLg} style={{ paddingRight: '5px' }} />
                                Home
                            </NavLink>

                        </li>
                        {/*<li className="nav-item px-3">*/}
                        
                        {/*    <NavLink tag={Link}  to="/resident-login">*/}
                        {/*              <FontAwesomeIcon icon={faPlus} style={{ paddingRight: '5px' }} />*/}
                        {/*        Customer Booking*/}
                        {/*    </NavLink>*/}
                        
                        {/*</li>*/}
                        {/*<li className="nav-item px-3">*/}
                        
                        {/*    <NavLink tag={Link}  to="/activities">*/}
                        {/*              <FontAwesomeIcon icon={faPlus} style={{ paddingRight: '5px' }} />*/}
                        {/*        Visitor Booking*/}
                        {/*    </NavLink>*/}
                        
                        {/*</li>*/}
                    </ul>
                    :
                      <ul className="nav flex-column">

                        <li className="nav-item px-3">

                            <NavLink tag={Link} to="/">
                                <FontAwesomeIcon icon={faHomeLg} style={{ paddingRight: '5px' }} />
                                Home
                            </NavLink>

                        </li>
                        <li className="nav-item px-3">
                    
                            <NavLink tag={Link} className="nav-link" to="/booked-activities">
                                      <FontAwesomeIcon icon={faBook} style={{ paddingRight: '5px' }} />
                                My Bookings
                            </NavLink>
                    
                        </li>
                        <li className="nav-item px-3">

                            <NavLink tag={Link} className="nav-link" to="/activities">
                                      <FontAwesomeIcon icon={faBookOpen} style={{ paddingRight: '5px' }} />
                                Book Activity
                            </NavLink>

                        </li>
                        <li className="nav-item px-3">
                    
                            <NavLink tag={Link} className="nav-link" to="/"
                                onClick={this.onTriggerLogout} >
                            
                                      <FontAwesomeIcon icon={faMinusCircle} style={{ paddingRight: '5px' }} />
                                Sign Out
                            </NavLink>
                    
                        </li>
                    </ul>
                   }
                      
        </div>

     </div>
     );
  }
}


